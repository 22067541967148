import type { VWOExperiments } from '@/types/ThirdPartyIntegrations/VWO'
import { useEffect, useState } from 'react'
import { debug, error } from '@/services/Log'
import { FeatureToggle } from '@/services/Configuration'
import { assertion, bool, dict } from '@recoiljs/refine'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { caught } from '@/helpers/FP'
import { VWO_EXPERIMENT_TIMEOUT } from '@/data/constants/VWO_EXPERIMENT_TIMEOUT'

export const assertBooleanDict = assertion(dict(bool()), 'VWOExperiments is not a boolean dictionary')
export const localStorageExperimentSadPath = (operation: string) => (e: unknown) => {
  error(`localStorageExperimentSadPath [${operation}] failed: ${String(e)}`, e)
  return undefined
}
export const setExperimentResultOnLocalStorage = caught(({
  experimentKey,
  value,
}:{ experimentKey: keyof VWOExperiments, value: boolean }) => {
  if (typeof window === 'undefined') return
  const experiments = assertBooleanDict(JSON.parse(localStorage.getItem('vwoExperiments') || '{}'))
  localStorage.setItem('vwoExperiments', JSON.stringify({
    ...experiments,
    [experimentKey]: value,
  }))
})(localStorageExperimentSadPath('SET'))

export const getExperimentResultFromLocalStorage = caught((experimentKey: keyof VWOExperiments) => {
  if (typeof window === 'undefined') return undefined
  const experiments = assertBooleanDict(JSON.parse(localStorage.getItem('vwoExperiments') || '{}'))
  return experiments[String(experimentKey)] || undefined
})(localStorageExperimentSadPath('GET'))

// Documentation on how to implement VWO experiments is available on the file:
// types/ThirdPartyIntegrations/VWO.d.ts
export const getVWOExperiment = (experimentKey: keyof VWOExperiments): Promise<boolean> => {
  if (typeof window === 'undefined') {
    error('SSR VWO Experiment is not supported')
    return Promise.resolve(false)
  }
  if (typeof experimentKey !== 'string' || !experimentKey) {
    error('VWO Experiment experimentKey is not valid', {
      'String(experimentKey)': String(experimentKey),
      experimentKey,
    })
    return Promise.resolve(false)
  }
  const localStorageValue = getExperimentResultFromLocalStorage(experimentKey)
  if (typeof localStorageValue === 'boolean') {
    debug(`SF-SSR Get VWOExperiment Experiment: ${String(experimentKey)} from localStorage as ${String(localStorageValue)}`)
    return Promise.resolve(localStorageValue)
  }

  // VWO runs a code snippet if the variant is turned on
  // which calls 'VWOExperimentResolvers[experimentKey]' method if it exists
  // or 'VWOExperiments[experimentKey] = Promise.resolve(experimentValue)'
  // More details on types/ThirdPartyIntegrations/VWO.d.ts file
  debug(`SF-SSR Get VWOExperiment Experiment: ${String(experimentKey)}`)
  if (typeof window?.VWOExperiments?.[experimentKey] !== 'object') {
    const vwoExperiment = new Promise<boolean>((resolve) => {
      const timeout = setTimeout(() => {
        error(new Error(`VWO Experiment: ${String(experimentKey)} timeout`))
        resolve(false)
      }, VWO_EXPERIMENT_TIMEOUT)
      window.VWOExperimentResolvers = {
        ...(window.VWOExperimentResolvers || {}),
        // This 'resolve' will be called by VWO script with the experimentValue
        [experimentKey]: (val: boolean) => {
          debug(`VWO Experiment: ${String(experimentKey)} resolved to ${String(val)}`)
          resolve(val)
          setExperimentResultOnLocalStorage({ experimentKey, value: val })
          clearTimeout(timeout)
        },
      }
    })
    const currentValues: VWOExperiments | undefined = window.VWOExperiments
    const defaultValues: VWOExperiments = {}
    const newValues: VWOExperiments = {
      ...(currentValues || defaultValues),
      [experimentKey]: vwoExperiment,
    }
    window.VWOExperiments = newValues
  }
  return (
    window.VWOExperiments[experimentKey]
    || Promise.reject(new Error('VWO Experiment not found'))
  )
}

export const useGetExperiment = ({
  experimentKey,
  featureToggle,
  forceOnFeatureToggle,
  forceValue,
}:{
  experimentKey: keyof VWOExperiments,
  featureToggle?: FeatureToggle,
  forceOnFeatureToggle?: FeatureToggle,
  forceValue?: boolean,
}) => {
  const shouldForceValueOn = typeof forceValue === 'boolean' ? forceValue : null
  const shouldForceViaFeatureToggle = (
    forceOnFeatureToggle && isFeatureEnabled(forceOnFeatureToggle)
  ) ? true : null
  const [isExperimentOn, setIsExperimentOn] = useState<boolean | null>(
    shouldForceValueOn || shouldForceViaFeatureToggle || null,
  )
  useEffect(() => {
    if (typeof forceValue === 'boolean') {
      setIsExperimentOn(forceValue)
    }
  }, [forceValue])
  useEffect(() => {
    if (isExperimentOn !== null) return
    if (featureToggle && !isFeatureEnabled(featureToggle)) {
      setIsExperimentOn(false)
      return
    }
    getVWOExperiment(experimentKey)
      .then((value) => {
        setIsExperimentOn(value)
      })
      .catch((e) => {
        console.error('useGetExperiment Error', e)
      })
  }, [isExperimentOn, experimentKey, featureToggle])
  return !!isExperimentOn
}

export default getVWOExperiment
